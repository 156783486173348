.logo-container_2 {
    height: 80px;
    width: 100px;
    margin-top: 20px;
  }
  .logo_2 {
    width: 100%;
  }
  .slider-container {
    width: 100%;
    background-color: #e2e2e247;
    height: 902px;
  }

  .left-signup-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    gap: 60px;
    margin: 0px auto;
  }

  .signup-carousel {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
  }
  .carousel-image {
    width: 100%;
    height: 300px;
  }
  .img-1 {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
  .signup-adjust-size {
    width: 250px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .signup-slider-signup-heading {
    font-size: 20px;
    font-weight: bold;
  }
  .signup-slider-para {
    font-size: 16px;
    line-height: 1.8;
  }
  .signup-carousel .carousel .control-dots {
      position: absolute;
      bottom: 40px;
      margin: 10px 0;
      padding: 0;
      text-align: center;
      width: 100%;
      z-index: 1;
  }

  .carousel-style .carousel .control-dots .dot {
    width: 14px !important;
    height: 14px !important;
    background-color: rgb(61, 131, 202) !important;
  }
  
  @media (max-width: 767px) {
    .logo-container_2 {
      height: 50px;
      width: 100px;
    }
    .signup-carousel {
      margin: 10px;
    }
    .signup-slider-para {
      margin-top: 8px;
    }
  }
  

  