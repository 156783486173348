.footer-section-heading {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    color: #414141;
    margin: 0;
    margin-bottom: 20px;
}

.footer-section-heading-2 {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    color: #414141;
    margin-top: 20px;
    margin-bottom: 10px;
}

.footer-section-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-section-list li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer-section-list-item {
    text-align: left;
    color: #4a4a4a;
    font-size: 16px;
    text-decoration: none;
    margin: 2px 0px;
    font-weight: 400;
}

.footer-social-media-container {
    display: flex;
    gap: 5px;
    flex-direction: row;
    margin-bottom: 40px;
    align-items: center;
    flex-flow: wrap
}

.footer-social-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.bottom-text {
    margin: 10px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .footer-main-container {
        padding: 20px;
    }

    .footer-section-heading {
        text-align: left;
    }

    .footer-section-list li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (max-width: 475px) {
    .bottom-text {
        font-size: 16px !important;
    }
}