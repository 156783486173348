.footer-main-container {
  padding: 20px 100px;
  background-color: #f7f7f7;
}
.footer-section-heading {
  text-align: left;
  color: #414141;
}
.footer-section-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-section-list li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-section-list-item {
  text-align: left;
  color: #4a4a4a;
  font-size: 17px;
  margin: 3px 0px;
}
.footer-social-container {
  width: 170px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footer-social-icon {
  width: 50px;
  height: 50px;
}
.footerDoctorMargin{
  margin-top: 30px !important;
}
@media screen and (max-width: 991px) {
  .footer-main-container {
    padding: 20px;
  }
  .footer-section-heading {
    text-align: center;
  }
  .footer-section-list li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-social-container {
    justify-content: space-evenly;
  }
}
