.ourServicesHeaderPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.margintop{
    margin-top: 40px !important;
}
.Mui-checked{
    color: #4d96dd !important;
}

.ourServicesTitle {

    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ourServicesSubTitle {
    color: #4d96dd;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0;
}

.servicesCard {
    width: 100%;
    /* height: 260px; */
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.servicesCardImgBox {
    width: 100%;
    height: 160px;
    border-radius: 20px;
    cursor: pointer;
}



.servicesCarddImg {
    width: 100%;
    height: 160px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #aaaaaa4f;
}

.servicesCardText {

    color: #353535;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.topmargin {
    margin-top: 70px;
}


/* .footer-card-batge{
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.14);
    margin: 2rem !important;
    padding: 1rem !important;
    border-radius: 8px !important;
background: #FCFCFC !important;
} */


ul.ant-rate {
    padding-left: 12px;
}

.shortVideosCardImg {
    width: 100%;
    height: 150px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.shortVideosCardImgBox {
    width: 100%;
    height: 150px;
}

.shortVideosCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 240px;
    box-shadow: 0px 0px 12px #aaaaaa;
    border-radius: 20px;
    margin-bottom: 40px;
}

.ant-divider-horizontal {
    background-color: #EAEAEA;
    /* Set the background color to a lighter shade */
    margin: 16px 0;
    /* Optional: Add some margin to the Divider */
}

.shortVideosCardTextBox {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    padding-top: 15px;
    font-size: 16px;
    color: black;
    font-weight: 400;
}

.footer-Navigation {
    /* 127.778% */
    display: flex;
    justify-content: "space-between"
}

.footer-Navigation p {
    margin-top: 12px;
    color: #353535;

    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: 23px;
}

.top-doctor {
    color: #353535;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    display: flex;
    justify-content: space-between;
}

.female-doctor-css {
    max-width: 100%;
    max-height: 50%;
}

.code-coupun-class {
    background-color: #FFBE3C;
    color: white;
}

.display-flex-property-between {
    display: flex;
    justify-content: space-between;
}

.margin-left-sympton {
    margin-left: 20px;
}

.marginCarousel {
    margin-top: 50px;
}


.angio-plastic-symptom-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 0;
}

.angio-plastic-symptom-list>li {
    color: #353535;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 85.714% */
}

.angio-plastic-symptom-heading {
    color: #353535;
    text-align: start;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.angio-plastic-symptom-heading1 {
    color: #353535;
    text-align: start;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.navigation-list-css-container {
    padding-left: 7%;
    display: flex;
    gap: 11px;
}

.navigation-list-css-container2 {
    padding-left: 7%;
    display: flex;
    gap: 11px;
}

.navigation-list-css-container3 {
    padding-left: 7%;
    display: flex;
    gap: 11px;
}
@media(max-width:600px){
    .navigation-list-css-container3{
      flex-direction: column;
    }
}
.navigation-list-css {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.navigation-list-css2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.navigation-list-css3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}