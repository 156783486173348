.certification-container{
    margin: 32px 0 !important;
}


.certification-wrapper{
    display: flex;
    flex-direction: column;
    gap:8px;
}



.card-certification{
border-radius: 8px;
background: #FCFCFC;
box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.14);
}

.certificationWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px;
}
.card-certification-img{
    width: 84px !important;
    height: 84px !important;
}



 .certification-text-label{
        color: #353535 !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 23px !important;
        margin-top: 12px !important;
}


@media(max-width:600px){
    .certification-text-label{
        color: #353535 !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 23px !important;
        margin-top: 12px !important;
}
}
