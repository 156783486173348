.ourServicesHeaderPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.ourServicesTitle {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
}

.ourServicesSubTitle {
    color: #4d96dd;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 0;
}
.addNewBtn{
  font-size: 15px;
  color: #4d96dd;
    font-weight: 500;
    cursor: pointer;
    padding-top: 20px;
}


/* .servicesCard {
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 20px;
} */

.generalHealthCard {
    width: 100%;
    /* height: 260px; */
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.generalHealthCardImgBox {
    width: 100%;
    height: 181px;
    border-radius: 20px;
}

.servicesCardImgBox {
    width: 100%;
    height: 160px;
    border-radius: 20px;
}

.servicesCarddImg {
    width: 100%;
    height: 160px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #aaaaaa4f;
}

.generalHealthCarddImg {
    width: 100%;
    height: 160px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px #aaaaaa4f;
}


.servicesCardText {
    color: #000000cf;
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 0;
}

.topmargin {
    margin-top: 70px;
}

.cardRow {
    padding-top: 5px;
    margin-top: 25px;
    /* padding-bottom: 15px; */
}

.hospitalsRow {
    padding-top: 5px;
    margin-top: 25px;
    padding-bottom: 15px;
}

.therapyRow {
    padding-top: 5px;
    margin-top: 25px;
    padding-bottom: 15px;
}

.servicesSubText {
    color: #000000cf;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 8px;
}

.therapiesCard {
    height: 160px !important;
    position: relative;
    box-shadow: 0px 0px 12px #aaaaaa;
    background-color: white;
    margin-bottom: 30px;
    border-radius: 20px !important;
    cursor: pointer;
}

.servicesSubTextHide{
  display: none;
}
.therapiesCardtext {
    /* position: absolute; */
    /* top: 20px; */
    /* left: 10px;
    bottom: -60px; */
    margin-top: 12px;
    /* margin-bottom: 12px; */
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.imageHide {
    width: 0;
}

.doctorsNearbyCard {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: 0px 0px 12px #aaaaaa;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 30px;
}

.doctorsNearbyCardImgBox {
    width: 35%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doctorsNearbyCardImg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #80808029;
}

.doctorsNearbyCardText {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
}

.orthoImg{
    width: 100%;
    height: 250px;
}

.docNameTitle {
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.doctext {
    color: black;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.doc_specilization {
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
}
.rateBox {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    flex-flow: wrap;
}

ul.ant-rate {
    padding-left: 12px;
}

.reviewText {
    color: black;
    font-size: 16px;
    margin: 0;
}

.topHospitalsCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;
    box-shadow: 0px 0px 12px #aaaaaa;
    border-radius: 20px;
    margin-bottom: 40px;
}

.hospiotalImgBox {
    width: 100%;
    height: 150px;
}

.hospitalTextBox {
display: flex;
flex-direction: column;
justify-content: space-between;
    width: 90%;
    height: 50%;
    margin: 0 auto;
    padding: 20px 0 20px 0;
}

.hospitalImg {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.hospitalNameBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hospitalName {
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
    margin: 0;
    font-size: 20px;
    color: black;
    font-weight: 700;
}

.hospitalDistance {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: #000000c4;
}

.hospitalRate {
    width: 100%;
    padding-top: 15px;
    flex-flow: wrap;
}

.shortVideosCardImg {
    width: 100%;
    height: 150px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.shortVideosCardImgBox {
    width: 100%;
    height: 150px;
}

.shortVideosCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 240px;
    box-shadow: 0px 0px 12px #aaaaaa;
    border-radius: 20px;
    margin-bottom: 40px;
}

.shortVideosCardTextBox {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    padding-top: 15px;
    font-size: 16px;
    color: black;
    font-weight: 400;
}
.healthStoriesContainer {
  padding: 20px;
  background-color: #f9f9f9;

  .healthStoriesHeader {
    text-align: center;
    margin-bottom: 20px;

    .healthStoriesTitle {
      font-size: 24px;
      font-weight: bold;
      color: #333;

      @media (max-width: 768px) {
        font-size: 20px;
      }

      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
  }

  .healthStoriesWrapper {
    position: relative;

    .bigBackgroundBox {
      position: absolute;
      bottom: 115px;
      width: calc(21.33% + 18px);
      height: 205px;
      background: #fff;
      border: 2px solid #eeeeee;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      z-index: 0;
      right: 48px;

      @media (max-width: 768px) {
        width: calc(40% + 18px);
        height: 160px;
        right: 24px;
      }

      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  .healthStoriesItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

    .healthStoriesImage {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border: 2px solid #498ecd;

      @media (max-width: 768px) {
        width: 120px;
        height: 120px;
      }

      @media (max-width: 576px) {
        width: 100px;
        height: 100px;
      }
    }

    .healthStoriesText {
      margin-top: 10px;
      font-size: 16px;
      color: #555;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
}


ul.control-dots {
    height: 30px !important;
    padding-top: 10px !important;
}

li.dot {
    width: 15px !important;
    height: 15px !important;
}

li.dot.selected {
    background: #3881c1 !important;
}

.secondCarousel ul.slider.animated {
    width: 100%;
    height: 280px;
}


.secondCarousel .carousel.carousel-slider {
    overflow: initial;
}

.secondCarousel .carousel .control-dots {
    bottom: -42px;
}

.carousel-root.secondCarousel {
    margin-top: 30px;
}

.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important ;    
}

.activeColor{
    color: #4d96dd !important;
}

.location-input{
  background-color: #f4f4f4;
  font-size: 14px;
  font-weight: 400;
}

.location_sub_area{
  font-size: 14px;
  font-weight: 400;
  /* color: #282828; */
  /* font-family: "Encode Sans"; */
  line-height: normal;
 cursor: pointer;
}

.customise-input input{
  background-color: #f4f4f4;
  outline: none !important;
  border: 5px solid #f4f4f4 !important;
}

.ant-input-affix-wrapper{
  border: none !important;
}



@media(max-width:1200px) {
    .doctorsNearbyCardImg {
        width: 100px;
        height: 100px;
    }

    .docNameTitle {
        font-size: 18px;
    }

    .hospitalName {
        font-size: 16px;
    }
}

@media(max-width:1060px) {
    .doctorsNearbyCardImg {
        width: 90px;
        height: 90px;
    }
}

@media(max-width: 992px) {
.calendarContainer {
    flex-direction: column;
    flex-direction: column-reverse !important;
    width: 100%;
  }
  .appoint{
    max-width: 100% !important;
  }
}

@media(max-width:767px) {
    .hospitalDistance {
        font-size: 14px;
    }

    .hospitalName {
        font-size: 14px;
    }

    .doctorsNearbyCardImg {
        width: 80px;
        height: 80px;
    }
}

@media(max-width:680px) {
    .reviewText {
        font-size: 14px;
    }

    .anticon-star {
        font-size: 14px !important;
    }

    .docNameTitle {
        font-size: 13px;
    }

    .doctext {
        font-size: 13px;
    }
    .calendarContainer {
      flex-direction: column-reverse !important;
    }
  
}
.appointmentsId {
  display: flex;
  justify-content: space-between;
}

@media(max-width:575px) {
    .anticon-star {
        font-size: 16px !important;
    }

    .docNameTitle {
        font-size: 18px;
    }

    .doctext {
        font-size: 18px;
    }

    .doctorsNearbyCardImg {
        width: 100px;
        height: 100px;
    }

    .ourServicesTitle {
        font-size: 20px;
    }

    .ourServicesSubTitle {
        font-size: 16px;
    }
    .calendarContainer {
      flex-direction: column-reverse !important;
    }

}

.navigationContainer {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding-top: 12px;
}

.navigationContainer a {
    text-decoration: none;
    color: #3a3a3a;
    font-weight: 600;
    font-size: 16px;
}

.marginCarousel{
    margin-top: 70px;
}

.secondCarousel{
    margin-top: 30px;
}

.secondCarousel ul.slider.animated img.carouselImage {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.advertisementImg{
    width: 100%;
    height: 280px;
    border-radius: 20px;
}


.active{
    color: #3881c1 !important;
}
.appintlandingpageSearchTitle{
color: #353535;
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: 22px; 
}
.advertisementImgBox{
    margin-top: 20px;
    border-radius: 20px;
    margin-bottom: 40px;
}

/* --Schedule css -- */
.doctorSchedule-main {
    padding: 0px 80px;
  }
  
  .doctorSchedule-heading-left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .doctorSchedule-heading-left h1 {
    color: #2e2e2e;
  }
  
  .doctorSchedule-heading-left h4 {
    color: #929292;
    margin-top: 10px !important;
    font-size: 20px;
    margin-bottom: 20px !important;
  }
  
  .doctorSchedule-heading-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .add-new-schedule-btn {
    cursor: pointer;
    color: #498ECC;
    font-size: 20px;
    font-weight: 500;
  }
  
  /* card css */
  .noAppointments {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .appointments-cards-container {
    padding: 0px 20px;
    background-color: #e5e5e573;
    border-radius: 10px;
    height: auto;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 400px;
  }
  
  .appointments-cards-container-lengthzero {
    padding: 0px 20px;
    background-color: white;
    box-shadow: 2px 4px 8px 0px rgba(241, 240, 240, 0.731);
    border: 1px solid rgba(169, 167, 167, 0.1);
    border-radius: 10px;
    height: 280px;
  }
  
  .appointments-cards-container-lengthone {
    padding: 0px 20px;
    background-color: #e5e5e573;
    border-radius: 10px;
    height: 300px;
    overflow-x: scroll;
  }
  
  .marginRightTime {
    margin-right: 20px !important;
  }
  
  .flexDiv{
     display: flex;
     align-items: center;
     flex-flow: wrap;
  }
  
  .appointments-card-main {
    padding: 8px 20px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }
  
  .appointments-card-main-paymentDone {
    border: 1px solid #167586;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.04);
  }
  
  .appointments-card-main-paymentPending {
    border: 1px solid #FE604E;
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.04);
  }
  
  .appointments-card-left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .appointments-card-location {
    padding: 3px 8px;
    background-color: #fdc9be;
    border-radius: 5px;
    margin: 0px;
    text-transform: uppercase;
  }
  
  .appointments-card-profile-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
  }
  
  .appointments-card-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .appointments-card-img-container img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
  
  .appointments-card-details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 10px;
  }
  
  .appointment-age-gender-container,
  .appointments-card-name {
    margin: 0px;
  }
  
  .appointments-card-name {
    font-weight: 500;
    font-size: 14px;
  }
  
  .appointment-age-gender-container {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
  }
  
  .appointments-card-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 17px;
  }
  
  .paymentDone {
    color: #3AB896 !important;
    font-size: 16px;
  }
  .payCancel{
    display: flex;
    flex-direction: column;
  }
  .cancelTag{
    background-color: #f5c7c78a;
    color:red;
    padding:5px 0px;
    border-radius: 4px;
    text-align: center;
  }
  .paymentPending {
    color: #FE604E !important;
    font-size: 16px;
  }
  
  .paymentLocationDone {
    background-color: #E7FFF4;
    font-size: 14px;
  }
  
  .paymentLocationPending {
    background-color: #FFC6BF;
    font-size: 14px;
  
  }
  
  .paymentMessage-icon {
    margin: 0px 5px;
  }
  
  .appointments-card-right {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
  }
  
  .appointments-card-right h4 {
    margin: 2px 0px;
  }
  
  .patientType {
    padding: 2px 5px;
    background-color: #effcff;
    color: #498ECC;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .appointment-time {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px !important;
  }
  
  .doctorSchedule-calender-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 42px;
  }
  
  .doctorSchedule-calender-main .react-calendar__navigation__prev2-button,
  .doctorSchedule-calender-main .react-calendar__navigation__next2-button {
    display: none !important;
  }
  
  /* modal css */
  .doctor-schudule-modal-heading {
    margin: 2px;
    text-align: center;
    font-size: 18px;
  }
  
  .doctor-schedule-modal-section-heading {
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #474747;
    font-size: 16px;
  }
  
  .doctor-schudule-modal-form {
    padding: 10px 0px;
  }
  
  .doctor-schudule-modal-form-item {
    padding: 0px 20px;
  }
  
  .doctor-schedule-modal .ant-modal-close-x {
    display: none !important;
  }
  
  .selectDatetext {
    font-size: 18px;
  }
  
  .doctor-schudule-modal-form-item .ant-form-item .ant-form-item-label>label {
    font-weight: 500 !important;
    color: #8b8b8b;
    padding: 0px;
  }

  .css-13cymwt-control{
    border: 0px !important;
    border-bottom: 1px solid #80808078 !important;
    border-radius: 0px !important;
  }

  .css-t3ipsp-control{
    border: 0px !important;
    border-bottom: 1px solid #80808078 !important;
    border-radius: 0px !important;
  }

  .css-13cymwt-control:hover {
      border-color: white !important;
      border: 0px !important;
      border-bottom: 1px solid #80808078 !important;
  }
  
  .doctor-schudule-modal-form-item .ant-form-item-label {
    padding: 8px !important;
  }
  
  .doctor-schudule-modal-form-item .ant-input {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #dadada !important;
    margin-top: 10px
  }
  
  .doctor-schudule-modal-form-item .ant-input:focus {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #dadada !important;
    box-shadow: none;
  }
  
  .doctor-schedule-modal-calender,
  .doctor-schedule-modal-select-time {
    padding: 5px 20px;
  }
  
  .doctor-schedule-modal-calender .ant-picker {
    width: 100%;
  }
  .doctor-schedule-modal-calender,
  .modalRe{
      gap:20px;
      display: flex;
      justify-content: center;
  }
  .reModule .ant-col{
    max-width: 100% !important;
  }
  .selectDateRows{
    margin-top: 3%;
  }
  .doctor-schedule-modal-select-time-shift-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    gap:10px;
    cursor: pointer;
  }
  
  .doctor-schedule-modal-select-time-shift {
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    background-color: #dfeaf5;
    color: #262b31;
    font-weight: 600;
    display: flex;
    cursor: pointer;
  }

  .doctor-schedule-modal-select-time-shift-active{
    background-color: #2e8fdd !important;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    display: flex;
    cursor: pointer;
  }
  
  .time-shift-active {
    background-color: #488ec9;
    color: white;
  }
  
  .doctor-schedule-modal-select-time-shift-time-morning-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    flex-wrap: wrap;
  }
  
  .doctor-schedule-modal-select-time-shift-time-time {
    padding: 8px 12px;
    background-color: #dfeaf5;
    border: none;
    font-weight: 500;
    color: #30373f;
    border-radius: 5px;
    margin: 8px 0px;
    width: 100px;
  }
  .doctor-schedule-modal-select-time-shift-time-time-active{
    background-color: orange;
    width: 100px;
  }

  .doctor-schedule-modal-select-time-shift-time-time-noAvailable {
    padding: 8px 12px;
    background-color: #40414285;
    border: none;
    font-weight: 500;
    color: white;
    border-radius: 5px;
    margin: 8px 0px;
    width: 100px;
  }

  .selectButtonColor {
    padding: 8px 12px;
    background-color: red;
    border: none;
    font-weight: 500;
    color: #30373f;
    border-radius: 5px;
    width: 95px;
    margin: 8px 0px;
  }
  
  .select-time-shift-time-time {
    background-color: #fcb92e;
    color: white;
  }
  
  .doctor-schudule-modal-buttons-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 12%;
  }
  
  .doctor-schudule-modal-modal-btn {
    padding: 10px 20px;
    width: 150px;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: none;
    font-weight: 500;
    font-size: 15px;
  }
  
  .doctor-schudule-modal-buttons-container .btn-cancel {
    background-color: white;
    border: 1px solid #c5c5c5;
    color: black;
    width: 150px;
    height: 54px;
  }
  
  .doctor-schudule-modal-buttons-container .btn-add {
    background-color: #488ecc;
    color: white;
    width: 150px;
    height: 54px;
  }
  
  .add-vertical-line {
    display: inline-block;
    height: 13px;
    background-color: #454545;
    width: 2px;
    margin: 0px 5px;
  }
  
  /* modal css */
  
  @media screen and (max-width: 991px) {
    .doctorSchedule-main {
      padding: 30px 30px;
    }
  }
  
  @media screen and (max-width: 820px) {
  
    .appointments-card-age,
    .appointments-card-gender {
      font-size: 12px;
      font-weight: 500;
    }
  
    .appointments-card-name {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .appointments-card-name {
      font-size: 13px;
    }
  
    .appointment-age-gender-container {
      font-size: 12px;
    }
  
    .doctorSchedule-heading-left h1 {
      font-size: 22px;
    }
  }
  
  @media screen and (max-width: 575px) {
    .doctorSchedule-main {
      padding: 30px 20px;
    }
  
    .doctorSchedule-heading-left h1 {
      font-size: 22px;
    }
  
    .add-new-schedule-btn {
      font-size: 15px;
    }
  
    .appointments-card-main {
      padding: 5px;
    }
  
    .appointments-card-name {
      font-size: 13px;
    }
  
    .appointments-card-details-container {
      padding: 0px 2px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  
    .appointment-age-gender-container {
      font-size: 13px;
    }
  
    .appointments-card-location {
      font-size: 9px;
    }
  
    .appointments-card-center {
      font-size: 10px;
    }
  
    .appointments-card-right h4 {
      font-size: 12px;
    }
  
    .appointments-cards-container {
      padding: 4px;
    }
  
    .appointments-time-heading {
      font-size: 15px;
    }
  
    .doctorSchedule-calender-main {
      padding: 4px;
  
    }
  }
  
  @media screen and (max-width: 650px) {
    .appointments-card-name {
      font-size: 14px;
    }
  
    .appointment-age-gender-container {
      font-size: 13px;
    }
  
    .appointments-card-center {
      font-size: 14px;
    }
  
    .appointments-card-right h4 {
      font-size: 12px;
    }
  
    .paymentLocationPending {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .doctorSchedule-main {
      padding: 10px 10px;
    }
  
    .appointments-card-name {
      font-size: 15px;
    }
  
    .appointment-age-gender-container {
      font-size: 15px;
    }
  
    .doctorSchedule-heading-left h1 {
      font-size: 19px;
    }
  }
  
  @media screen and (max-width: 476px) {
    .appointments-card-name {
      font-size: 13px;
    }
  
    .appointment-age-gender-container {
      font-size: 12px;
    }
  
    .appointments-card-center {
      font-size: 14px;
    }
  
    .appointments-card-right h4 {
      font-size: 12px;
    }
  
    .doctorSchedule-heading-left h1 {
      font-size: 17px;
    }
  
    .doctor-schedule-modal-select-time-shift {
      padding: 10px;
      border-radius: 5px;
      border: none;
      background-color: #dfeaf5;
      color: #262b31;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 440px) {
    .appointments-card-center {
      font-size: 13px;
    }
  
    .appointments-card-name {
      font-size: 14px;
    }
  
    .appointment-age-gender-container {
      font-size: 13px;
    }
  
    .doctorSchedule-heading-left h1 {
      font-size: 15px;
    }
  
    .doctorSchedule-heading-left h4 {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 420px) {
    .appointments-card-center {
      font-size: 12px;
    }
  
    .appointments-card-name {
      font-size: 11px;
    }
  
    .appointments-card-img-container img {
      width: 40px;
      height: 40px;
    }
  
    .appointments-card-right h4 {
      font-size: 10px;
    }
  }
  
  .pageContainer {
    width: 89%;
    margin: 0 auto;
  }
  
  .appointmentscheduleheading {
    font-size: 24px;
  }
  
  .appointments-time-heading {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0 10px 0;
  }
  
  .schdeuleSelect.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: transparent !important;
  }
  
  .schdeuleSelect.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid white !important;
  }
  
  .schdeuleSelect.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  
  .doctor-schudule-modal-form.ant-form-vertical .ant-form-item .ant-form-item-control {
    height: 30px !important;
  }
  
  .marginTop{
    margin-top: "20px"
  }
  
  .doctorSchedule-calender-main .ant-picker {
    visibility: hidden;
  }
  
  
  
  @media(max-width:1250px) {
  
    .doctorSchedule-main {
      padding: 30px 68px;
    }
  }
  
  
  @media(max-width:990px) {
    .appointmentscheduleheading {
      font-size: 20px;
    }
  
    .add-new-schedule-btn {
      font-size: 16px;
    }
  }
  
  @media(max-width:800px) {
  
    .appointment-time {
      font-size: 12px;
    }
  
    .paymentDone {
      font-size: 12px;
    }
  }

  @media(max-width:767px) {
    .doctorSchedule-calender-main{
      padding-top: 10px !important
    }
    .patientDetailsRow {
      height: 310px !important;
    }
  }
  
  .doctorSchedule-calender-main .react-calendar {
    /* width: 280px !important; */
    border: 1px solid #ecece9 !important;
  }
  
  .symptomsBoxContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .symptomsBox {
    width: 283px;
    height: 100px;
    border: 1px solid #00000029;
    border-radius: 10px;
    overflow-x: scroll;
    margin-right: 17px;
  }
  
  .symptomsText {
    width: 95%;
    border-bottom: 1px solid #ecece9;
    margin: 0 auto;
    height: 32px;
    display: flex;
    align-items: center;
  }
  
  .patientDetailsRow {
    height: 180px;
  }
  
  .selectDateRow{
     margin-top: 10%;
  }
  
  .ant-select-multiple .ant-select-selection-item {
    height: 36px !important;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    align-items: center;
  }
  
  .borderSymptoms.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-bottom: 1px solid #d9d9d9 !important;
  }
  
  .ant-select-multiple .ant-select-selection-item-remove {
    font-size: 14px !important;
  }