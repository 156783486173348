.contentwrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    gap: 24px;
}

.offers_card{
border-radius: 15px;
background: #FCFCFC;
box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.14);
}


.textWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 200px;

}

.promo_title{
color: #353535;
font-family: Encode Sans;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.doctor_img{
    max-width: 149px;
    width:100%;
    height:96px;
}

.offerlabel_button{
   background-color: #FFBE3C !important;
   border-radius: 4px !important;
   color: #fff !important;
font-family: Encode Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
height: 35px;
}


.offerlabel_button:hover{
    background-color: #FFBE3C !important;
 }

.verifiedDoc_img{
    max-width: 40px;
    width: 100%;
    height: auto;

}

.promo_title_span{

    color: #353535;
    font-family: Encode Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.promo_title_span_doctors{
    color: #353535;
    font-family: Encode Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

