 .Cardwrapper {
   display: flex;
   flex-direction: column;
   gap: 8px,
 }

 .cardMedicineImgWrapper {
   width: 224px;
   height: 200px
 }

 .cardMedicineImg {
   width: 100%;
   height: 100px;
 }

 .cardImageText {
   color: #353535;
   font-size: 15px;
   font-Style: normal;
   font-Weight: 600;
   line-Height: normal;
 }

 .cardImageTextSmall {
   color: #353535;
   font-size: 10px;
   font-Style: normal;
   font-Weight: 600;
   line-Height: normal;
 }

 .paragraphStyle {
   margin: 0px;
 }

 .bannerStyle {
   height: 297px;
   width: 100%;
 }

 .text-input-css {
   border-radius: 12px;
   border: 0.5px solid #B8B8D2;
   background: #FFF;
   padding: 14px;

 }
.addDetails{
  font-weight: bold;
  padding:30px 10px
}
 .heading {
   color: #353535;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
 }

 .headerLogo {
   width: 98.526px;
   height: 78px;
   flex-shrink: 0;
 }

 .navigation-list-css-container {
   padding-left: 7%;
   display: flex;
   gap: 11px;
   margin-top: 50px;
   margin-bottom: 25px;
 }

 .goback {
  padding-left: 7%;
  display: flex;
  gap: 11px;
  margin-top: 16px;
  margin-bottom: 25px;
}

 .Rs-text {
   color: #353535;
   font-size: 18px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
 }

 .check-out-button {
   width: 160px;
   height: 32px;
   color: #FFF;
 }

 .card-text {
   color: #353535;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
 }

 .cart-text-heading {
   margin-top: 50px;
   color: #353535;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
 }

 .side-card-css {
   width: 358;
   padding: 0px;
   border-radius: 8px;
   background: #FFF;
   box-shadow: 0px 2px 13px 0px rgba(88, 87, 87, 0.16);
   margin: 22px auto;
   margin-top: 9rem;
   margin-bottom: 3rem;
 }

 .text-label-css {
   color: #353535;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   height: 25px;
 }

 .save-btn-form {
   border-radius: 29.5px !important;
   background: #498ECC !important;
   width: 10%;
   height: 50px !important;
   color: #FFFFFF;
 }

 .box-with-shadow {
   width: 100%;
   height: 9rem;
   background-color: #fff;
   box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.3);
 }

 .mainWrapper-medicine {
   display: flex;
   margin: 0 auto;
   width: 90%;
   justify-content: space-around;
   gap: 30px;
 }

 .btn-css-coupon {
   border-radius: 8px;
   background: rgb(255, 255, 255) none repeat scroll 0% 0%;
   box-shadow: rgba(88, 87, 87, 0.16) 0px 2px 13px 0px;
   width: 100%;
   height: 64px;
   display: flex;
   gap: 23px;
   align-items: center;
 }

 @media(max-width:650px) {
   .mainWrapper-medicine {
     flex-direction: column;
   }

   .card-details-css {
     width: 364px;
     height: 274px;
   }
 }

 .card-details-css {
   width: 455px;
   height: 155px;
 }

 @media(max-width:650px) {
   .mainWrapper-medicine {
     flex-direction: column;
   }

   .card-details-css {
     width: 364px;
     height: 274px;
   }
 }