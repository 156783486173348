.doctorDetailsPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 330px;
  background: #f4f4f4;
  border-radius: 10px;
  position: absolute;
  top: 65px;
  right: 70px;
  padding: 20px 20px;
  z-index: 9999;
  box-shadow: 13px -1px 11px 0 rbga(0,0,0,0.04);
}

.patientPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: auto;
  background: #f4f4f4;
  border-radius: 10px;
  position: absolute;
  top: 65px;
  right: 70px;
  padding: 20px 20px;
  z-index: 9999;
  box-shadow: 13px -1px 11px 0 rbga(0,0,0,0.04);
}
@media(max-width:468px){
  .patientPopupContainer {
    width: 70%;
  }
  .container{
    flex-direction: column;
    align-items: flex-start !important;
  }
  .doctor_detailsPopup_container{
    width: 70% !important;
  }
}

.doctor_detailsPopup_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 330px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  top: 65px;
  right: 70px;
  padding: 20px 20px;
  z-index: 9999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.closeModelOff{
  display: none;
}
.closeModel{
  left: 0;
  top: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, 0);
  z-index: 8;
}
.horizontalFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.leftDetailsContainer {
  width: 90%;
  gap: 5px;
  display: flex;
  align-items: center;
}
.openEditProfile {
  cursor: pointer;
  width: 5%;
}
.verticalFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.doctor-profile-picture {
  height: 60px !important;
  width: 60px !important;
  object-fit: cover;
  border-radius: 50%;
}
.doctor-name-text {
  margin: 2px;
  font-size: 18px;
  font-weight: 700;
}
.edit-profile-text {
  font-size: 12px;
  margin: 2px;
  font-weight: 300;
  cursor: pointer;
}
.callDetailsConatiner {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.callDetailsConatiner {
  margin: 10px 0;
  width: 100%;
}
.callHeading {
  margin: 2px;
  font-size: 15px;
}
.callerProfile {
  color: rgb(66, 142, 199);
  margin: 0;
}
.callerName {
  color: rgb(66, 142, 199);
  margin: 0 5px;
}
.CallIcon {
  color: rgb(66, 142, 199);
  cursor: pointer;
}
.navigationFeatures {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0px;
  gap: 10px;
}
.navigationFeatures p {
  margin-bottom: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  height: 30px;
}
.logout-text {
  color: rgb(66, 142, 199);
}
.footerLogo {
  margin-bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.footerLogoImg {
  width: 100px;
  height: 80px;
}

@media(max-width:1230px){
  .doctorDetailsPopupContainer {
      right: 0px;
      left: 56px;
      top: 128px;
    }
}

@media(max-width:991px){
  .doctorDetailsPopupContainer {
      right: 0px;
      left: 150px;
      top: 236px;
    }
}

@media(max-width:798px){
  .doctorDetailsPopupContainer {
      right: 0px;
      left: 150px;
      top: 321px;
    }
}


@media(max-width:375px){
    .doctorDetailsPopupContainer {
        right: 0px;
        left: 0px;
        width: 100%;
      }
}