@import url('../../blocks/splashscreen/src/LandingPage.css');
@import url('../../blocks/social-media-account-registration/src/SignUp.css');
@import url('../../components/src/CarouselCommonComponent.css');
@import url('../../components/src/Navbar.css');
@import url('../../blocks/email-account-login/src/Login.css');
@import url('../../components/src/Footer.css');
@import url('../../blocks/splashscreen/src/PatientRegistration.css');
@import url('../../components/src/CertificationComponent.css');
@import url('../../blocks//appointmentmanagement/src/patientAppoinment.css');
@import url('../../components/src/PatientAppointmentLandingpage.css');
@import url('../../blocks/email-account-registration/src/DoctorRegister.css');
@import url('../../blocks/shoppingcart/src/Services.css');
@import url('../../blocks/dataencryption/src/PatientProfile.css');
@import url('../../blocks/photolibrary/src/medicine.css');

@import url('../../blocks/splashscreen/src/DoctorDashboard/DoctorDashboardHomePage.css');
@import url('../../components/src/FooterDoctor.css');
@import url('../../components/src/DoctorDetails.css');
@import url('../../components/src/PatientPopup.css');
@import url('./fonts/stylesheet.css');




a{
    text-decoration: none !important;
}

iframe{
display: none;
position: relative;
}


*::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #b5b6b7;
    border-radius: 10px;
    border: 1px solid #b5b6b7 ;
  }


.ant-modal-mask {
    background-color:rgb(36 34 34 / 19%) !important ;
}

.rupay {
  width: 14px;
  height: 14px;
}

* {
  /* font-family: Encode sans semibold; */
}

input[type=file] {
  display: none!important;
}

.ant-select-multiple .ant-select-selection-item-content {
  width: 26px;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #4d96dd !important;
}

.ant-notification.ant-notification-topRight {
  z-index: 9999 !important;
}

.selectDayss.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.hideData{
  display: none
}

.ant-form-item-explain-error {
  color: #ff4d4f;
  z-index: 9999 !important;
  position: relative;
  padding-top: 10px;
}
.formType .ant-form-item-explain-error {
  color: #ff4d4f;
  z-index: -1 !important;
  position: relative;
  padding-top: 10px;
}

.ant-list-empty-text{
  display: none;
}