.DoctorDashboardHomePage-main {
  padding: 0px 80px;
}
/* navbar css */
.DoctorDashboardHomePageNavbar-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0px;
}
.DoctorDashboardHomePageNavbar-list-item p {
  cursor: pointer;
  color: #3a3a3a;
  font-weight: 600;
}
.DoctorDashboardHomePageNavbar-list-item p:hover {
  color: #669fca;
}
.doctorActive {
  color: #4d96dd !important;
}
/* navbar css */
/* upcomming appoitment css */
.renderUpCommingAppointment-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.renderUpCommingAppointment-heading {
  font-weight: 500;
  color: #3a3a3a;
  font-size: 28px;
}
span.anticon.anticon-bell {
  font-size: 22px;
}
.appointment-cards-container {
  max-height: 470px;
  overflow-y: scroll;
  width: 102%;
}
.container_upcoming{
  display: none; 
}
.appointment-card-main {
  padding: 10px;
  width: 99%;
  margin: 10px 0px;
  border-radius: 10px;
  border: 1px solid rgb(234, 234, 234);
  box-shadow: 1px 1px 3px 1px rgb(209, 207, 207);
}
.renderUpCommingAppointment-view-all-btn {
  color: #4d96dd !important;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 0px !important;
}
.appointment-card-left {
  display: flex;
  justify-self: flex-start;
}
.appointment-userImg-container {
  padding: 2px;
}
.appointment-userImg-container img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.appointment-card-left-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  /* border: 1px solid red; */
}
.card-name-section {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.card-name-section .appointment-user-name,
.card-name-section .appointment-user-age {
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  color: #454545;
}
.card-name-section .vertical-line {
  height: 13px;
  background-color: #454545;
  width: 2px;
  margin: 0px 5px;
}
.appointment-location-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 3px 0px;
}
.location-icon {
  width: 30px;
  height: 30px;
}
.location-name {
  font-size: 17px;
  margin: 5px 0px;
}
.user-tag {
  margin: 0px 10px !important;
  padding: 3px 10px !important;
  background-color: #e6fff7;
  color: #71b6a4 !important;
  border-radius: 5px;
  font-weight: 600;
}
.appointment-user-left-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}
.appointment-user-time-date-container {
  display: flex;
  justify-content: space-between;
  height: 40px;
  gap: 30px;
  /* border: 1px solid red; */
  width: 350px;
  margin: 5px 0px;
}
.date-container,
.time-container {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2faff;
  padding: 0px 5px;
  border-radius: 5px;
  /* width: 150px; */
}
.date-container img,
.time-container img {
  margin: 0px 5px;
}
.date-container p,
.time-container p {
  color: #43494d;
  padding: 0px !important;
  margin: 0px 5px;
  font-weight: 500;
  font-size: 14px;
}
/* upcomming appoitment css */
/* my availability */
.myAvailability-main {
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px rgb(209, 207, 207);
  padding: 10px;
  border: 1px solid rgb(209, 207, 207);
  height:260px;
  overflow-y: scroll;
}
.availabilty_container{
  display: none;
}
.myAvailability-card {
  padding: 5px;
}
.myAvailability-card-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.myAvailabilty-time {
  margin: 0px 10px !important;
  font-size: 15px;
  text-align: left;
  font-weight: 700;
  color: #383838;
}
.myAvailabilty-location {
  margin: 8px 10px;
  font-size: 15px;
  text-align: left;
  color: #383838;
}
.myAvailability-card-right {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 50px;
}
.horizontal-line {
  height: 1px;
  background-color: rgb(196, 195, 195);
  margin: 5px 0px;
}

.performanceSummary-card-img-container {
  height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.performanceSummary-arrow-img {
  width: 100px;
}
.value1 {
  font-size: 25px;
  font-weight: 800;
  color: #3a3a3a;
}
.value2 {
  color: #a8a8a8;
  margin-left: 5px;
}
.myAvailability-card-left-section,
.myAvailability-card-center-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* my availability */
/* performance summary */
.performanceSummary-card-left-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.performanceSummary-card-left-section p {
  font-size: 22px;
  font-weight: 500;
  color: #3a3a3a;
}
.performanceSummary-card-center-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* performance summary */
@media screen and (max-width: 876px) {
  .card-name-section .appointment-user-name {
    font-size: 13px;
    text-align: left;
  }
  .appointment-user-time-date-container {
    gap: 10px;
  }
  .date-container,
  .time-container {
    width: 130px;
  }
  .user-tag {
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  .DoctorDashboardHomePage-main {
    padding: 0px 70px;
  }
  .appointment-user-left-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .appointment-user-time-date-container {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .DoctorDashboardHomePage-main {
    padding: 0px 50px;
  }
  .DoctorDashboardHomePageNavbar-list {
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  }
  .renderUpCommingAppointment-heading {
    font-size: 22px;
  }
  .myAvailability-card-right {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 518px) {
  .card-name-section p,
  .location-name {
    font-size: 14px;
  }
}
@media screen and (max-width: 476px) {
  .DoctorDashboardHomePage-main {
    padding: 0px 40px;
  }
  .card-name-section p,
  .location-name {
    font-size: 13px;
  }
}
