
    .landingPageContainer {
        width: 92% !important;
        margin: 0 auto;
        min-height: 125px;
    }
/* 
@media only screen and (max-width: 1190px) {
    .landingPageContainer {
        width: 92% !important;
        margin: 0 auto;
        height: 145px ;
    }
  } */


/* @media only screen and (max-width: 992px) {
    .landingPageContainer {
        width: 92% !important;
        margin: 0 auto;
        height: 145px ;
    }
  } */
  
.headerLogoBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 130px;
    margin-right: 50px;
    height: 70px;
    /* margin-bottom: 55px; */
}

.header_orveride{
    display: flex !important;
    justify-content: baseline !important;
    align-items: flex-start !important;
}

.headerLogoImg {
    width: 100%;
    height: 75px;
    object-fit: contain;
}

.headerSearchBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
}

.locationBox {
    height: 48px;
    background-color: #f4f4f4;
    border-radius: 7px;
    display: flex;
    align-items: center;
}

.locationImgBox {
    width: 30px;
    height: 30px;
}
.showMsg{
    color: red;
    font-weight: 400;
    font-size: 14px;
    padding-left: 20px;
}
.locationImg {
    width: 100%;
    height: 100%;
    margin-left: 10px;
}

.locationImg svg {
    width: 100%;
    height: 100%;
}
.listStyle{
    display: flex;
    width: 45%;
    justify-content: space-between;
}
@media(max-width:1280px){
    .listStyle{
       flex-direction: column;
       width: 35%;
    }
}
@media(max-width:600px){
    .listStyle{
       width: 100%;
    }
}
.locationTest {
    width: 223px;
    padding-left: 20px;
    margin: 0;
    font-size: 16px;
    color: grey;
}

.searchBoxDoc {
    background-color: #f4f4f4;
    width: 500px !important;
    height: 48px;
    border-radius: 7px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    padding: 0 16px 0 16px;
    justify-content: space-between;
    position: relative;
    display: flex;
    align-items: center;
}
.headingStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.locationStyle{
    display: flex;
    padding-right: 5%;
    gap:10px;
}
.searchBoxDoc_doctor {
    background-color: #f2f2f2;
    width: 100%;
    height: 48px;
    border-radius: 7px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    padding: 0 20px 0 20px;
    justify-content: space-between;
}

.searchInput_doctor {
    outline: 0;
    border: 0;
    background-color: transparent;
    width: 98%;
    height: 100%;
}
.searchInput {
    outline: 0;
    border: 0;
    background-color: transparent;
    width: 430px;
    height: 100%;
}

span.anticon.anticon-search {
    font-size: 24px !important;
}

span.anticon.anticon-audio {
    font-size: 24px;
}

.headerLinkBox {
    display: flex;
}

span.anticon.anticon-shopping-cart.linkCartImg {
    font-size: 24px;
}

span.linkCartBox {
    margin: 3px 20px 0px 0;
    cursor: pointer;
    font-size: 24px;
}

.particition {
    margin: 0 6px 0 6px;
    font-size: 18px;
    color: #3881c1;
    font-weight: 500;
}

.linkText {
    font-size: 18px;
    color: #3881c1;
    font-weight: 600;
    cursor: pointer;
}

.registerText {
    font-size: 18px;
    color: #3881c1;
    font-weight: 500;
    margin-left: 18px;
    cursor: pointer;
}

.SearchLocationBox {
    flex-flow: wrap;
    align-items: flex-start !important;
    margin-left: 30px;
}

@media(max-width:1440px) {
    .searchBoxDoc {
        width: 400px;
    }

    .locationBox {
        width: 215px;
    }
}


@media(max-width:991px) {
    .headerLogoBox {
        margin-bottom: 0px;
        margin-left: 0px;
    }

    .headerLogoImg {
        margin-left: 0;
    }

    .headerSearchBox {
        margin-left: 0px;
        width: 90%;
        margin-top: 15px;
    }

    .headerLinkBox {
        margin-left: 0px;
        margin-top: 15px;
    }
    .SearchLocationBox {
        margin-left: 0px;
        height: 160px !important;
    }
    .navbarRightFeature{
       margin-top: 10px;
    }

}

@media(max-width:900px){
    .headerSearchBox {
        flex-direction: column;
        gap:10px
    }
    .searchBoxDoc{
        margin-left: 0px;
        width:60% !important
    }
    .locationBox{
        width: 60%;
    }
}
@media(max-width:480px){
    .searchBoxDoc{
        width:90% !important
    }
    .locationBox{
        width: 90%;
    }
}
.ant-modal-content{
box-shadow: none;
}
.ant-modal-close{
    display: none;
}

.cancelBtn{
    position: absolute;
       top: 21;
       right: 3%;
       z-index:999;
       color:#cecbcb;
       cursor: pointer
        }
@media(max-width:797px) {
    .searchBoxDoc {
        margin-left: 0px;
        width: 500px;
        margin-top: 15px;
    }

    .SearchLocationBox {
        height: 200px !important;
    }
    .D9WLyEQaARfWqCTyVrpU{
        flex-direction: column !important;
        gap: 20px !important;
    }

}

@media(max-width:575px) {
    .searchBoxDoc {
        width: 400px;
    }

    .searchInput {
        width: 320px;
    }

    .SearchLocationBox {
        height: 200px !important;
    }

}

@media(max-width:410px) {
    .searchBoxDoc {
        width: 350px;
    }

}

.carouselImage {
    width: 100%;
    height: 350px;
}

.itemContainerWiidth {
    width: 90%;
    margin: 0 auto;
}

.boxMargin {
    margin-bottom: 30px;
}



  @media(max-width:1024px) {
    .navbarRightFeature{
        display: flex;
        align-items: flex-start;
        align-items: center;
        width: 250px;
        padding-top: 16px !important;
    }
}



.navbarRightFeature{
    display: flex;
    align-items: flex-start;
    align-items: center;
    width: 250px;
    /* justify-content: space-between; */
}
.rupayImage{
    height: 25px;
    width: 25px;
}
.navbarRightIconContainer{
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    font-size: 25px;
}
.navbarUserProfileContianer{
    display: flex;
    align-items: center;
    width: 30%;
    font-size: 14px;
    gap:8px;
    font-weight: 600;
}
.profilePhotoContainer{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
.profilePhotoContainer img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
}
.navbarRightFeature .pointer{
cursor: pointer;
}
.navbarDoctorHome {
    justify-content: flex-end;
    margin-right: 30px;
    align-items: center;
    height: 50px;
    display: flex;
    width: 70%;
}


input.searchInput::placeholder {
    padding-left: 12px;
}

.downIcon{
    font-size: 18px;
    cursor: pointer;
}
.notificationImg{
    width: 24px;
    height: 24px;
}

.profileimg{
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

@media(max-width:1230px) {
   .removeWidth{
      width: 20px;
   }
   
   .navbarDoctorFeature{
      justify-content:initial;
   }

}
.ant-avatar-sm{
    padding: 4px;
}
.ant-avatar > img{
    object-fit: contain;
}