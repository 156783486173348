.registerInput.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-block-end: -5px;
    color:red;
    font-size: 21px;
    position: absolute;
    left: 100%;
    top: 3%;
}
.registerInput.ant-form-item .ant-form-item-label >label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 20px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
}
.registerInput .ant-input {
    padding: 8px 11px;
    color: rgba(0, 0, 0, 0.88);
}
.registerInput .ant-input:hover {
    border-inline-end-width: 1px;
}
.registerInput .ant-input:focus, :where(.css-dev-only-do-not-override-12upa3x).ant-input-focused {
    box-shadow: none;
}
.registerInput.ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
}
.registerInput .ant-select-selector {
    border-color:#d9d9d9;
    box-shadow:none;
    outline: 0;
}
.registerInput .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
}
.registration-continue-button{
    width: 280px;    
    height: 50px;
    color:white;
    border-radius: 40px;
    font-weight: 600;
    font-size: 16px;
    background-color: rgb(66,143,199) !important;
}
.verification-form{
    margin: 20px 0;
}
.modal-heading{
    margin: 10px 0px;
    font-size: 25px;
}
.upload-label{
    color:black;
    font-weight: 600;
    font-size: 16px !important;
}
.verification-desc-heading{
    color:gray;
    margin: 0px 0px;
    font-size: 16px;
    font-weight: 500;
}
.verification-button-container{
    display: flex;
    width: 60%;
    margin: 0px auto;
    justify-content: space-evenly;
}
.registration-skip-button{
    width: 250px;    
    height: 50px;
    padding:15px auto;
    color:gray !important;
    margin-right: 5px;
    font-size: 16px;
    border:2px solid gray;
    border-radius: 40px;
    font-weight: 600;
    background-color:white !important;
}
.upload-container{
    background-color:#e2e2e247 !important;
    width: 100%;
    height: 105px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    cursor: pointer;
}
.upload-icon{
    border:none;
    height: 40px;
    width: 40px;
    font-weight: 600;
    border-radius: 50%;
    background-color: rgb(234,234,234)
}
.upload-instruction{
    margin: 8px 0;
    font-weight: 600;
    color:rgb(153,153,153);
}
.upload-icon .plusIconStyle{
    font-weight: 800;
    color:rgb(153,153,153);
    font-size: 25px;
}
.upload-info{
    color:gray;
    margin: 8px 0;
    font-weight: 500;
}
.down-icon-style{
    color:black;
    font-size: 14px;
}
.upload-error{
    color:red;
    margin: 5px 0;
}
.fillDocument{
    height: 100%;
    width: 100%;
    object-fit: contain;

}
.specializationSelect.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 40px !important;
}
@media(max-width:575px){
    .verification-button-container{
        width: 100%;
    }
}
