.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-container {
  align-items: flex-start;
}
.nameWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rightIcon {
  display: flex;
  justify-content: space-around;
}


.doctor-profile-picture {
  width: 94.667px;
  height: 142px;
}

.Patient-name-text {
  color: #353535;
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

.healthId-profile-text {
  color: #353535;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-Top: '5px'
}

.Patient-navigationFeatures {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 20px 0px; */

  color: #353535;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  

}

.Patient-navigationFeatures p {
  margin-bottom: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.logout-text {
  color: rgb(66, 142, 199);
}

.footerLogo {
  margin-bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.footerLogoImg {
  width: 100px;
  height: 100px;
}
.pop-text-item {
  color: #353535;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
}

.pop-text-item:hover {
  color: #498ECC;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;

}