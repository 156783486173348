.patientRegHeading {
  font-size: 25px;
  margin: 0px 0;
  font-weight: 700;
}
.patientForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
  justify-content: space-between;
}
.headingSection{
  display:flex;
  justify-content: space-between;
  width:100%

}
.patientRegFormLeftContainer {
  width: 100%;
}
.symptonsDetails{
  width: 50%;
}
@media(max-width:900px){
  .symptonsDetails{
    width:100%
  }
}
.cancelBtn-primary{
  color: #fff;
  background-color: rgb(66, 142, 199);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 16px;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 20px;
  width: 100px;
  cursor:pointer
}

.patient-registration-style .ant-form-item {
  margin-bottom: 5px;
}
.patient-registration-style .ant-input {
  border-radius: 10px;
  height: 40px;
}
.patient-registration-style .ant-form-item .ant-form-item-label {
    padding: 0 0 2px;
}
.patient-registration-style .ant-btn-primary {
  color: #fff;
  margin-top: 15px;
  background-color: rgb(66, 142, 199);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.patient-registration-style .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-block-end: -5px;
    font-size: 20px;
    position: absolute;
    left: 100%;
    top: -2px !important;
}
.patient-registration-style .ant-form-item-label >label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 20px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
    font-weight: 300;
}
.patient-registration-style .ant-picker {
    width: 100%;
    height: 40px;
    border-radius: 10px !important;
}
.patient-registration-style .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    border-radius: 10px;
}

.patient-registration-style .ant-btn {
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  padding: 4px 15px;
  border-radius: 20px;
  width: 300px;
  margin: 0 auto;
}
.patientRegFormRightContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.uploadIPatientmageContainer {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: white;
  position: relative;
  background-color: rgb(66, 142, 199);
}
.uploadPatientImageAction {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  z-index: 1000;
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  cursor: pointer;
  background-color: rgb(66, 142, 199);
}
.uploadedPatientImage {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
  border: 1px solid white;
}
.down-icon-style{
    color:black;
    font-size: 14px;
}
.submitButton{
    display: flex;
    align-items: center;
    justify-content: center;
}
.documentLabel{
  color:black;
  font-weight: 600;
  font-size: 16px !important;
}
.patientVerifysubHeading{
  color:gray;
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 500;
}
.patientVerifyButtoncontainer{
  display: flex;
  width: 60%;
  margin: 0px auto;
  justify-content: space-evenly;
}
.patientVerifySkipButton{
  width: 250px;    
  height: 50px;
  padding:15px auto;
  color:gray !important;
  margin-right: 5px;
  font-size: 16px;
  border:2px solid gray;
  border-radius: 40px;
  font-weight: 600;
  background-color:white !important;
}
.patientVerifyContinueButton{
  width: 250px;    
  height: 50px;
  padding:15px auto;
  color:white !important;
  margin-right: 5px;
  font-size: 16px;
  border:2px solid transparent;
  border-radius: 40px;
  font-weight: 600;
  background-color: rgb(66, 142, 199) !important;
}
.documentContainer{
  background-color:rgb(249,249,249);
  width: 100%;
  height: 105px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  cursor: pointer;
}
.uploadSection{
  border:none;
  height: 40px;
  width: 40px;
  font-weight: 600;
  border-radius: 50%;
  background-color: rgb(234,234,234)
}
.uploadDoctInstruction{
  margin: 8px 0;
  font-weight: 600;
  color:rgb(153,153,153);
}
.uploadSection .plusIconStyle{
  font-weight: 800;
  color:rgb(153,153,153);
  font-size: 25px;
}
.uploadDocInfo{
  color:gray;
  margin: 8px 0;
  font-weight: 500;
}
.down-select-icon{
  color:black;
  font-size: 14px;
}
.uploadDocError{
  color:red;
  margin: 5px 0;
}
.patientDocumentImage{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 575px) {
  .uploadIPatientmageContainer {
    height: 90px;
    width: 90px;
    font-size: 35px;
    background-color: rgb(66, 142, 199);
  }
  .uploadPatientImageAction {
    height: 35px;
    width: 35px;
    top: 0;
    right: -5px;
    text-align: center;
    display: flex;
    font-size: 18px;
  }
  .patient-registration-style .ant-btn {
    width: 200px;
  }
}
